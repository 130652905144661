import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import Select from 'react-select'
import { ApiHook } from '../../hooks/apiHook';
import { useQueryClient } from '@tanstack/react-query';


export default function UserDetailsTree({ profile }) {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const legLockOptions = [
        { label: t("right"), value: "right" },
        { label: t("left"), value: "left" },
        { label: t("weak_leg"), value: "weak_leg" },
    ]
    const [binaryLeg, setBinaryLeg] = useState(profile?.binaryLeg);

    const updateMutation = ApiHook.CallUpdateBinaryLeg();
    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
    const toggleEditMode = () => {
        setIsEditModeEnabled(!isEditModeEnabled);
    };
    const changeBinaryLeg = async({ value }) => {
        setBinaryLeg(value)
        await updateMutation.mutateAsync({ binaryLeg: value })
        setIsEditModeEnabled(false)
        queryClient.refetchQueries({ queryKey: ["profile-tree"] })
    }
    return (
        <div><div className="profileStatusSecTree">
            <div className="profileStatusBg">
                <div className="profileStatusContDetailSecTree">
                    <div className="profileStatusIconBg">
                        <i className="fa-solid fa-user" style={{ color: "#5e28fb" }}></i>
                    </div>
                    <div className="statusnameCount">
                        <h6>{t("personalPV")}</h6>
                        {profile?.pv !== null && profile?.pv !== undefined ? (
                            <p>{profile?.pv}</p>
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                </div>
                <div className="profileStatusContDetailSecTree">
                    <div className="profileStatusIconBgtwo">
                        <i
                            className="fa-solid fa-user-group"
                            style={{ color: "#7e6711" }}
                        ></i>
                    </div>
                    <div className="statusnameCount">
                        <h6>{t("groupPV")}</h6>
                        {profile?.gpv !== null && profile?.gpv !== undefined ? (
                            <p>{profile?.gpv}</p>
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                </div>
                <>
                    <div className="profileStatusContDetailSecTree">
                        <div className="profileStatusIconBgthree">
                            <i
                                className="fa-solid fa-arrow-left"
                                style={{ color: "#2c628a" }}
                            ></i>
                        </div>
                        <div className="statusnameCount">
                            <h6>{t("leftCarry")}</h6>
                            {profile?.leftCarry !== null &&
                                profile?.leftCarry !== undefined ? (
                                <p>{profile?.leftCarry}</p>
                            ) : (
                                <Skeleton />
                            )}
                        </div>
                    </div>
                    <div className="profileStatusContDetailSecTree">
                        <div className="profileStatusIconBgfour">
                            <i
                                className="fa-solid fa-arrow-right"
                                style={{ color: "#207b70" }}
                            ></i>
                        </div>
                        <div className="statusnameCount">
                            <h6>{t("rightCarry")}</h6>
                            {profile?.rightCarry !== null &&
                                profile?.rightCarry !== undefined ? (
                                <p>{profile?.rightCarry}</p>
                            ) : (
                                <Skeleton />
                            )}
                        </div>
                    </div>
                    <div className="profileStatusContDetailSecTree">
                        <div>
                            <div style={{ display: "flex" }}>

                                <h5>{t("leg_lock")}</h5>
                                <span
                                    style={{ textDecoration: "none", cursor: "pointer", paddingLeft: "15px" }}
                                    onClick={toggleEditMode}
                                >
                                    <i
                                        className="fa-solid fa-pen-to-square"
                                        style={{ color: "#32009c" }}
                                    ></i>
                                </span>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12">
                                <Select
                                    id="1"
                                    value={legLockOptions?.find((item) => item.value === profile?.binaryLeg)}
                                    isDisabled={!isEditModeEnabled}
                                    isSearchable={false}
                                    options={legLockOptions}
                                    onChange={changeBinaryLeg}
                                />
                            </div>


                        </div>
                    </div>
                </>

            </div>
        </div></div>
    )
}
