import React, { useState } from 'react'
import BinaryVolumeReport from '../../components/Report/BinaryVolumeReport';
import { useTranslation } from 'react-i18next';
export default function BinaryVolume() {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    return (
        <>
            <div className="page_head_top">{t("binary_volume_report")}</div>
            <BinaryVolumeReport currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </>
    )
}
