import React, { useState } from 'react'
import { ApiHook } from '../../hooks/apiHook';
import TableMain from '../Common/table/TableMain';

export default function BinaryVolumeReport({ currentPage, setCurrentPage }) {
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const headers = ["fromUser", "leftLeg", "rightLeg", "leftCarry", "rightCarry", "dateOfSubmission", "action"]
    const binaryVolumes = ApiHook.CallBinaryVolumeReport({ page: currentPage, perPage: itemsPerPage })
    return (
        <>
            <div className="table-responsive min-hieght-table">
                <TableMain
                    headers={headers}
                    data={binaryVolumes?.data?.data}
                    startPage={1}
                    currentPage={currentPage}
                    totalPages={binaryVolumes?.data?.totalPages}
                    setCurrentPage={setCurrentPage}
                    type={"binary-volume-report"}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                />
            </div>
        </>
    )
}
