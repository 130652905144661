import React, { useState } from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap';
import _ from "lodash"
import { ApiHook } from '../../hooks/apiHook';
import QuizComplete from './QuizComplete';
import QuizAnswerPreview from './QuizAnswerPreview';
import QuizBody from './QuizBody';
import QuizFooter from './QuizFooter';
import { t } from 'i18next';

export default function QuizModal({ data, show, setShow, handleClose, handleShow, currentVideo, setQuiz, refetchVideoList }) {
    const submit = ApiHook.CallValidateTest()
    const [qIndex, setqIndex] = useState(0)
    const [quizComplete, setQuizComplete] = useState(false)
    const [quizPayload, setQuizPayload] = useState({})
    const handleModalClose = () => {
        setQuizPayload({})
        handleClose()
    }
    const nextHandler = () => {
        if (qIndex < (data.questions.length)) setqIndex(qIndex + 1)
    }
    const prevHandler = () => {
        if (qIndex > 0) setqIndex(qIndex - 1)

    }
    const finishHandler = async () => {
        const payload = {
            questions: data.questions,
            answers: quizPayload,
            historyId: currentVideo?.userViewed.id,
            videoId: currentVideo?.id,
            categoryId: currentVideo?.category
        }
        const result = await submit.mutateAsync(payload)
        if (result.data.status) setQuizComplete(result?.data?.data)
    }
    const setAnswer = (option) => {
        const qstnData = data.questions[qIndex]
        const exists = _.some(quizPayload[qIndex], { id: option.id })
        let payload = quizPayload[qIndex]
        if (exists) {
            _.remove(payload, { id: option.id });
            setQuizPayload({ ...quizPayload, [qIndex]: payload })
            return
        }
        if (!payload) {
            payload = [option]
        } else if (payload && payload.length < qstnData.answerCount) {
            payload.push(option)
        } else {
            payload.shift()
            payload.push(option)
        }
        setQuizPayload({ ...quizPayload, [qIndex]: payload })
    }
    const restartQuiz = () => {
        setQuizPayload({})
        setqIndex(0)
        setQuizComplete(false)
    }
    return (
        <>
            <div className={show ? 'modal-blur-background blurred full-screen-div ' : 'modal-blur-background'}>
                <Modal show={show} onHide={handleModalClose} fullscreen={true}>
                    <Modal.Header>
                        <Modal.Title >{t("quiz")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {quizComplete ?
                            <>
                                <QuizComplete quizComplete={quizComplete} data={data} />
                            </> :
                            <>
                                {qIndex === (data?.questions?.length) ?
                                    <QuizAnswerPreview
                                        questions={data.questions}
                                        quizPayload={quizPayload}
                                    /> :
                                    <QuizBody
                                        setAnswer={setAnswer}
                                        data={data}
                                        qIndex={qIndex}
                                        quizPayload={quizPayload}
                                    />
                                }
                            </>

                        }
                    </Modal.Body>
                    <Modal.Footer style={{ paddingRight: "20px" }}>
                        <QuizFooter
                            qIndex={qIndex}
                            quizComplete={quizComplete}
                            quizPayload={quizPayload}
                            restartQuiz={restartQuiz}
                            prevHandler={prevHandler}
                            finishHandler={finishHandler}
                            nextHandler={nextHandler}
                            data={data}
                            setShow={setShow}
                            setQuiz={setQuiz}
                            refetchVideoList={refetchVideoList}
                        />
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    );
}
