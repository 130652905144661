import { t } from 'i18next';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function VideoList({ setCurrentVideo, videoList, currentVideo, onSave, onReset }) {
    const [show, setShow] = useState(false)
    const [video, setVideo] = useState("")

    const handleModalClose = () => {
        setShow(false)
    }
    const resetModalHandler = (data) => {
        setVideo(data)
        setShow(true)
    }
    const resetHandler = () => {
        onReset(video)
        setShow(false)
    }
    const selectVideo = (video) => {
        if (video?.userViewed || currentVideo?.id == video?.id) { setCurrentVideo(video) } else { toast.error(t("prev_complete")) }
    }
    return (<>
        {videoList &&
            <>
                <div>
                    {videoList?.map((video) => {
                        return (
                            <div className='row'>
                                <div className={`video-item-container`} onClick={() => selectVideo(video)}>
                                    <div className={`video-list-item${currentVideo?.id == video?.id ? "-selected" : !video.userViewed ? "-locked" : ""}`}>
                                        <div className='col-md-8'>{`${video.title}`}</div>
                                        <div className='col-md-4'>
                                            <div className='video-list-icons'>
                                                {currentVideo?.id == video?.id ?
                                                    <>
                                                        {/* <i class="fa-regular fa-bookmark fa-lg control-icons"
                                                            onClick={() => onSave({ videoId: video?.id, historyId: video?.userViewed?.id ?? currentVideo?.userViewed?.id }, "save")}
                                                        >
                                                        </i> */}
                                                        <i class="fa-solid fa-rotate-left fa-lg control-icons"
                                                            onClick={() => resetModalHandler({ videoId: video?.id, historyId: video?.userViewed?.id ?? currentVideo?.userViewed?.id })}
                                                        >
                                                        </i>
                                                        {video?.userViewed?.testPassed ? <i class="fa-solid fa-circle-check fa-lg control-icons"></i> : <i class="fa-solid fa-clock fa-lg"></i>}
                                                    </> :
                                                    video.userViewed ? video?.userViewed?.testPassed ? <i class="fa-solid fa-circle-check fa-lg control-icons" style={{ "color": "#4e9a06" }}></i>
                                                        : <i class="fa-regular fa-clock fa-lg control-icons"></i>
                                                        :
                                                        <i class="fa-solid fa-lock fa-lg control-icons"></i>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                    })}
                </div>
                <Modal show={show} onHide={handleModalClose}>
                    <Modal.Header>
                        <h5>{t("reset_progress")} ?</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t("reset_confirm")}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='primary' onClick={handleModalClose}>{t("cancel")}</Button>
                        <Button variant='danger' onClick={resetHandler}>{t("reset")}</Button>
                    </Modal.Footer>
                </Modal></>
        }
    </>)
}
