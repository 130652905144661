import API from "../../api/api";

export const lmsServcies = {
  getVideoList: async ({ category }) => {
    const response = API.get(`video-list?category=${category}`);
    return response;
  },
  getSingleVideo: async ({ currentVideoId, category }) => {
    const response = API.get(`get-single-video?videoId=${currentVideoId ?? "latest"}&category=${category}`);
    return response;
  },
  updateVideoProgress: async (data) => {
    const response = API.patch(`update-video-progress`, data);
    return response;
  },
  updateVideoComplete: async (data) => {
    const response = API.patch(`update-video-complete`, data);
    return response;
  },
  getTestQuestions: async (id) => {
    const response = API.get(`get-test-questions?videoId=${id}`);
    return response
  },
  validateTest: async (data) => {
    const response = API.post(`validate-test`, data);
    return response;
  },
  getVideoCategories: async () => {
    const response = API.get(`category-list`);
    return response;
  },
  getLmsTestHistories: async (page, perPage) => {
    const response = API.get(`get-customer-view-history?page=${page}&perPage=${perPage}`);
    return response;
  },
};
