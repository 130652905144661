import React from 'react'
import { Col, Row } from 'react-bootstrap'
import _ from "lodash"

export default function QuizBody({ qIndex, data, setAnswer, quizPayload }) {
    return (
        <>
            <div className='quiz-body'>
                <div className='quiz-question-section'>
                    <h4>{`${qIndex + 1}. ${data.questions[qIndex].question}`}</h4>
                    {data.questions[qIndex].answerCount > 1 && <ul><li><p className='quiz-question-instructions'>{`select ${data.questions[qIndex].answerCount} options`}</p></li></ul>}
                    <br />
                </div>
                <div>
                    <Row>
                        {data.questions[qIndex].options.map((option) => {
                            return (
                                <>
                                    <Col md={6}>
                                        <div style={{ paddingTop: "10px" }}>
                                            <div className={` ${_.some(quizPayload[qIndex], { id: option.id }) ? "quiz-options-selected" : "quiz-options"}`}
                                                onClick={() => setAnswer(option)}>
                                                <p className='container-flex'>{option.options}</p>
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            )
                        })}
                    </Row>
                </div>
            </div>
        </>
    )
}
