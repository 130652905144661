import API from "../../api/api";


const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const ReportService = {
    getBinaryVolumeReport: async ({ page, perPage }) => {
        const response = await callApi(`binary-volume-report?page=${page}&perPage=${perPage}`);
        return response
    },

}