import React, { useState } from 'react'
import { ApiHook } from '../../hooks/apiHook'
import MainVimeo from './MainVimeo'
import { t } from 'i18next'
import CategoryList from '../../components/Vimeo/CategoryList'

export default function Categories() {
    const categoryList = ApiHook.CallVideoCategories()
    const [category, setCategory] = useState(false)
    const [categoryName, setCategoryName] = useState("")

    const backToCategories = () => {
        setCategory(false)
        categoryList.refetch()
    }
    const setSelectedCategory = (data) => {
        setCategory(data.id)
        setCategoryName(data.name)
    }
    return (
        <>
            <div className="page_head_top">{t("lms")}</div>
            {!category ? categoryList?.data?.data?.data &&
                <CategoryList categories={categoryList?.data?.data?.data} setSelectedCategory={setSelectedCategory} />
                : <MainVimeo category={category} categoryName={categoryName} backToCategories={backToCategories} />
            }
        </>
    )
}
