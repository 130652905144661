import React from 'react'
import { useLocation } from 'react-router';

export default function Logout() {
    const location = useLocation()
    window.location.href = (location.state.link);
    return (
        <div className="theme-loader">
            <div className="spinner">
                <div className="spinner-bounce one"></div>
                <div className="spinner-bounce two"></div>
                <div className="spinner-bounce three"></div>
            </div>
        </div>
    )
}
