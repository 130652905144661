import { t } from 'i18next'
import React from 'react'

export default function QuizAnswerPreview({ questions, quizPayload }) {
    return (
        <>
            <div className='quiz-answer-preview'>
                {questions.length > 0 ? <>
                    <h4 className='text-centre'>{t("verify_answer")}</h4><br />
                    {questions.map((e, index) => {
                        return (
                            <>
                                <p><h6>{`${index + 1}. ${e?.question}`}</h6>
                                    <ul>{quizPayload[index]?.length > 0 ?
                                        quizPayload[index]?.map((option) => { return <li>{option?.options}</li> }) :
                                        <li>{t("no_answer_selected")}</li>}
                                    </ul>
                                </p>
                            </>)
                    })
                    }</> :
                    <>
                        <h4>{t("no_quiz")}</h4>
                    </>}

            </div>
        </>
    )
}
