import { t } from 'i18next';
import React from 'react'
import { Button } from 'react-bootstrap';

export default function QuizFooter({ qIndex, quizComplete, restartQuiz, prevHandler, finishHandler, nextHandler, data, quizPayload, setShow, setQuiz, refetchVideoList }) {
    const toVideoMenu = () => {
        setQuiz(false)
        setShow(false)
        refetchVideoList()
    }
    return (
        <>
            {quizComplete ?
                <>
                    {!quizComplete.testPassed && <Button variant="primary" onClick={restartQuiz}>{t("retry")}</Button>}
                    <Button variant={quizComplete.testPassed ? "primary" : "danger"} onClick={toVideoMenu}>{quizComplete.testPassed ? t("continue") : t("exit")}</Button>
                </> :
                <>
                    {qIndex > 0 && <Button variant="secondary" onClick={prevHandler}>{t("prev")}</Button>}
                    {qIndex === (data.questions.length) ?
                        <Button variant="primary" onClick={finishHandler}>{data.questions.length === 0 ? t("proceed") : t("submit")}</Button> :
                        <Button variant="primary" onClick={nextHandler} disabled={!quizPayload[qIndex] || (quizPayload[qIndex]?.length < data?.questions[qIndex]?.answerCount)}>{t("next")}</Button>
                    }
                </>}
        </>
    )
}
