import React, { useState } from 'react'
import QuizModal from './QuizModal'
import { ApiHook } from '../../hooks/apiHook';
import { Button } from 'react-bootstrap';

export default function Quiz({ show, setShow, setQuiz, currentVideo ,refetchVideoList}) {
    const questionData = ApiHook.CallTestQuestions(currentVideo?.id)
    const handleClose = () => {
        setShow(false)
        setQuiz(false)
    };
    const handleShow = () => setShow(true);
    return (<>
        {/* <div>
            <p>*Quiz Instructions*</p>
        </div> */}
        {/* <Button variant="danger" onClick={() => setQuiz(false)}>Withdraw</Button>{" "}
        <Button variant='primary' onClick={() => setShow(true)}>Proceed</Button> */}

        {questionData?.data?.data?.data &&
            <QuizModal
                data={questionData?.data?.data?.data}
                show={show}
                setShow={setShow}
                handleShow={handleShow}
                handleClose={handleClose}
                currentVideo={currentVideo}
                setQuiz={setQuiz}
                refetchVideoList={refetchVideoList}
            />
        }
    </>
    )
}
