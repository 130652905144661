import { t } from 'i18next'
import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'


export default function CategoryList({ categories, setSelectedCategory }) {
    const navigate = useNavigate()
    const toReport = () => {
        navigate("/lms-quiz-report")
    }
    const isDistributor = useSelector((state) => state.dashboard?.appLayout?.isDistributor)
    return (
        <>
            {isDistributor && <div className='row'>
                <div className='col-md-12 quiz-report'>
                    <Button onClick={toReport}>{t("report")}</Button>
                </div>
            </div>}
            {categories?.length === 0 ?
                <div className="no-data-div">
                    {/* <div className="no-data-div-image-category">
                        <img src="/images/noCategory.jpg" alt="" className='no-category-image' />
                    </div>
                    <br />
                    <p>{t("noDataFound")}</p> */}
                </div>
                :
                <Row>
                    {categories?.map((category) => {
                        return <>
                            <Col md={2} style={{ margin: "10px", width: "auto" }}>
                                <div className='category-folder' >
                                    <img className='category-folder-img' src={`/images/folder(0).png`} alt="" onClick={() => setSelectedCategory(category)} />
                                    <p>{category.name}</p>
                                </div>
                            </Col>
                        </>
                    })}
                </Row>
            }
        </>
    )
}
