import { t } from 'i18next'
import React from 'react'
import { Card } from 'react-bootstrap'

export default function QuizComplete({ quizComplete, data }) {
    return (
        <>
            {data.questions.length === 0 ?
                <div className='quiz-answer-preview'>
                    <Card style={{ border: "none" }}>
                        <Card.Body className={`quiz-result-view ${quizComplete.testPassed ? 'quiz-result-view-pass' : "quiz-result-view-fail"}`}>
                            <div>{quizComplete.testPassed &&
                                <div>{t("qualified")} <i class="fa-solid fa-circle-check fa-lg" style={{ color: "#36da16" }}></i></div>}
                            </div>
                            {/* <div>{`Your score is ${quizComplete.totalQuestionsPassed} out of ${quizComplete.totalQuestions}`}</div> */}
                        </Card.Body>
                    </Card>
                </div>
                :
                <div className='quiz-answer-preview'>
                    <Card style={{ border: "none" }}>
                        <Card.Body className={`quiz-result-view ${quizComplete.testPassed ? 'quiz-result-view-pass' : "quiz-result-view-fail"}`}>
                            <div>{quizComplete.testPassed ?
                                <div>{t("passed")} <i class="fa-solid fa-circle-check fa-lg" style={{ color: "#36da16" }}></i></div> :
                                <div>{t("failed")} <i class="fa-solid fa-circle-xmark fa-lg" style={{ color: "#e61919" }}></i></div>}
                            </div>
                            <div>{`Your score is ${quizComplete.totalQuestionsPassed} out of ${quizComplete.totalQuestions}`}</div>
                        </Card.Body>
                    </Card>
                </div>}
        </>
    )
}
