import React, { useState } from "react";
import TableMain from "../Common/table/TableMain";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";

const ViewHistoryTable = () => {
    const navigate = useNavigate()
    const toLms = () => {
        navigate("/lms")
    }
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

   const headers = [
        "Id",
        "customer",
        "video",
        "category",
        "Status",
        "quiz",
    ];
    //------------------------------------ API -------------------------------------------
    const quizHistory = ApiHook.CallQuizHistory(
        currentPage,
        itemsPerPage,
    );
    return (
        <>
            <div className="page_head_top">{t("lms_quiz_report")}</div>
            <div className='row'>
                <div className='col-md-12 quiz-report'>
                    <Button onClick={toLms}>{t("categories")}</Button>
                </div>
            </div>
            <div className="ewallet_table_section">
                <div className="ewallet_table_section_cnt">

                    <div className="table-responsive min-hieght-table">
                        <>

                            <TableMain
                                headers={headers}
                                data={quizHistory?.data?.data?.data?.data}
                                startPage={1}
                                currentPage={currentPage}
                                totalPages={quizHistory?.data?.data?.data?.totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                                setItemsPerPage={setItemsPerPage}
                                type={"quiz-history"}
                            />
                        </>
                    </div>
                </div>
            </div>
        </>

    );
};

export default ViewHistoryTable;
