
import { ReportService } from "../../services/report/report";

export const getBinaryVolumeReport = async ({page, perPage}) => {
    try {
        const response = await ReportService.getBinaryVolumeReport({page, perPage});
        return response;
    } catch (error) {
        return error.message;
    }
}

