import React from 'react'
import Vimeo from '@u-wave/react-vimeo'
import SubmitButton from '../Common/buttons/SubmitButton';
import { t } from 'i18next';

export default function VimeoPlayer({ setQuiz, setShow, currentVideo, onFinish, setDuration, videoProgressHandler, keyVal }) {
    const handleQuiz = () => {
        setQuiz(true)
        setShow(true)
    }
    console.log("currentVideo?.videoUrl ==== ",currentVideo?.videoUrl);
    return (
        <>
            {currentVideo && currentVideo?.videoUrl &&
                <>
                    <div className='vimeo-player-background'>
                        <Vimeo
                            key={keyVal}
                            video={currentVideo?.videoUrl}
                            onPause={() => videoProgressHandler({ videoId: currentVideo?.id, historyId: currentVideo?.userViewed?.id })}
                            onEnd={() => onFinish({ videoId: currentVideo?.id, historyId: currentVideo?.userViewed?.id })}
                            onTimeUpdate={(data) => setDuration(data.seconds)}
                            responsive
                            start={Number(currentVideo?.userViewed?.progress) >= 1 ? Number(currentVideo?.userViewed?.progress) - 1 : 0}

                        // onPlay={() => console.log("Playing...")}
                        // onProgress={(data) => console.log("Progressing...", data)}
                        // width={"900"}
                        // height={"800"}
                        // dnt={true}
                        // showByline={false}
                        // onLoaded={() => console.log("Loaded --- ")}
                        // show_title={false}
                        // show_byline={false}
                        // show_portrait={false}
                        />
                    </div>
                    <div className='vimeo-video-details'>
                        <div className='row'>
                            <div className='col-md-8'>

                                <div className='video-title'><b>{currentVideo?.title}</b></div>
                            </div>
                            <>
                                <div className='col-md-4 quiz-button-div'>

                                    {currentVideo?.userViewed?.testPassed ? <div>{`${t("test_passed")} `}<i class="fa-regular fa-circle-check fa-lg" style={{ "color": "#4e9a06" }}></i></div> :
                                        <SubmitButton
                                            isLoading={false}
                                            click={handleQuiz}
                                            text={"quiz"}
                                            className="btn btn-primary"
                                            disabled={currentVideo?.userViewed?.completed != 1}
                                        />}
                                </div>
                            </>
                        </div>
                        <p>{currentVideo?.description}</p>
                    </div>
                </>}
        </>
    )
}
